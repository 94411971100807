import { SAVE_MENU_SIDEBAR, SAVE_TITLE_HEADER, SAVE_LOADINGS, SAVE_SOCIAL, SAVE_FOOTER } from '@/constants/ActionTypes';

  const initialState = {
    menu: 'gold',
    title: '',
    spin: false,
    social: [],
    footer: '',

};
const layoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_MENU_SIDEBAR:
            return {
                ...state,
                menu: action.payload
            }; 
        case SAVE_LOADINGS:
            return {
                ...state,
                spin: action.payload
            };
        case SAVE_SOCIAL:
            return {
                ...state,
                social: action.payload
            };
        case SAVE_FOOTER:
            return {
                ...state,
                footer: action.payload
            };
        case SAVE_TITLE_HEADER:
            return {
                ...state,
                title: action.payload
            };
        default:
            return { ...state };
    }
};
export default layoutReducer;