import { UPDATE_LIST_CART } from '@/constants/ActionTypes';

const initialState = {
  carts: {},
};
const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_LIST_CART:
            return {
                ...state,
                carts: action.payload || {}
            };
        default:
            return { ...state };
    }
};
export default cartReducer;