import {
  LOAD_PRICE_CHART
} from '@/constants/ActionTypes';

const initialState = {
  gold_price_chart: {},
};

const priceChartReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PRICE_CHART:
      return {
        ...state,
        gold_price_chart: action.payload || {}
      };
    default:
      return { ...state };
  }
};

export default priceChartReducer;