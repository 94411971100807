// ================= Home Part ==================== //
export const SAVE_LOADINGS = 'SAVE_LOADINGS';
export const SAVE_SOCIAL = 'SAVE_SOCIAL';
export const SAVE_FOOTER = 'SAVE_FOOTER';
export const SAVE_PROVINCES = 'SAVE_PROVINCES';

export const SAVE_USER_REF = 'SAVE_USER_REF';
export const SAVE_DETAIL_PARTNERS = 'SAVE_DETAIL_PARTNERS';

export const AGENCY_LIST_SEND_GOLD = 'AGENCY_LIST_SEND_GOLD';
export const AGENCY_LIST_WITH_DRAW_GOLD = 'AGENCY_LIST_WITH_DRAW_GOLD';
export const AGENCY_LIST_COMMISSION = 'AGENCY_LIST_COMMISSION';

// ================= REGISTER_AGENCY ==================== //
export const REGISTER_AGENCY = 'REGISTER_AGENCY';


// ================= User Part ==================== //

export const FETCH_USER_LIST = 'FETCH_USER_LIST';
export const FETCH_USER_LIST_FAIL = 'FETCH_USER_LIST_FAIL';
export const FETCH_USER_LIST_SUCCESS = 'FETCH_USER_LIST_SUCCESS';



export const LOAD_CATEGORY_PRODUCT = 'LOAD_CATEGORY_PRODUCT';
export const LOAD_LIST_PRODUCT = 'LOAD_LIST_PRODUCT';

export const LOAD_LIST_NEWS = 'LOAD_LIST_NEWS';


export const SAVE_INFO_USER = 'SAVE_INFO_USER';
export const SAVE_LIST_USER_LOGIN_PERMISSION = 'SAVE_LIST_USER_LOGIN_PERMISSION';
export const SAVE_LOGIN = 'SAVE_LOGIN';

export const SAVE_MENU_SIDEBAR = 'SAVE_MENU_SIDEBAR';
export const SAVE_TITLE_HEADER = 'SAVE_TITLE_HEADER';

export const UPDATE_LIST_CART = 'UPDATE_LIST_CART';

export const LOAD_LIST_BANK = 'LOAD_LIST_BANK';
export const LOAD_LIST_BANK_USER = 'LOAD_LIST_BANK_USER';
export const LOAD_LIST_BANK_TRANSFER = 'LOAD_LIST_BANK_TRANSFER';
export const LOAD_EXCHANGE_RATE = 'LOAD_EXCHANGE_RATE';
export const LOAD_LIST_STORE = 'LOAD_LIST_STORE';
export const LOAD_LIST_AGENCY = 'LOAD_LIST_AGENCY';

export const LOAD_LAST_KYC = 'LOAD_LAST_KYC';
export const SAVE_KYC = 'SAVE_KYC';
export const SET_WITH_DRAW = 'SET_WITH_DRAW';


export const SAVE_ADDRESS = 'SAVE_ADDRESS';
export const UPDATE_ADDRESS_CART = 'UPDATE_ADDRESS_CART';

export const UPDATE_CHART_OVERVIEW = 'UPDATE_CHART_OVERVIEW';
export const UPDATE_CHART_PRICE_GOLD = 'UPDATE_CHART_PRICE_GOLD';
export const LOAD_HOT_NEWS = 'LOAD_HOT_NEWS';
export const LOAD_NEWS_HOME = 'LOAD_NEWS_HOME';

export const LIST_REFFERAL = 'LIST_REFFERAL';

export const OVERVIEW_REFERRAL = 'OVERVIEW_REFERRAL';

export const UPDATE_SELL_GOLD = 'UPDATE_SELL_GOLD';
export const UPDATE_BUY_GOLD = 'UPDATE_BUY_GOLD';
export const UPDATE_WITH_DRAW = 'UPDATE_WITH_DRAW';
export const UPDATE_RECHARGE = 'UPDATE_RECHARGE';
export const UPDATE_GOLD_TRANSFER = 'UPDATE_GOLD_TRANSFER';
export const UPDATE_BUY_PRODUCT = 'UPDATE_BUY_PRODUCT';
export const UPDATE_MONEY = 'UPDATE_MONEY';
export const CANCEL_BANK_TRANSFER = 'CANCEL_BANK_TRANSFER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';


export const SHOW_POPUP_LOGIN = 'SHOW_POPUP_LOGIN';
export const UPDATE_CHART_GOLD = 'UPDATE_CHART_GOLD';
export const LOAD_ALL_AGENCY = 'LOAD_ALL_AGENCY';

export const LOAD_PRICE_CHART = 'LOAD_PRICE_CHART';