import { 
    UPDATE_LIST_CART
} from '../../constants/ActionTypes';
import { fetchApi } from "../../utils/api";

export function updateOrderCart(payload) {
    return {
        type: UPDATE_LIST_CART,
        payload
    };
}

export const addProduct = async (payload, dispatch) => {
    try {
        let response = await fetchApi(`/app/order/add-product`, "post", payload);
        if (response.code === 200){
            dispatch(updateOrderCart(response.data));
        }
        return response;
    } catch (error) {
        return {
            message: "Có lỗi sảy ra, vui lòng thử lại sau",
            signal: 0
        };
    }
};

export const setProduct = async (payload, dispatch) => {
    try {
        let response = await fetchApi(`/app/order/create-or-set-product`, "post", payload);
        if (response.code === 200){
            dispatch(updateOrderCart(response.data));
        }
        return response;
    } catch (error) {
        return {
            message: "Có lỗi sảy ra, vui lòng thử lại sau",
            signal: 0
        };
    }
};
export const updateOrderInfo = async (payload, dispatch) => {
    try {
        let response = await fetchApi(`/app/order/update`, "post", payload);
        if (response.code === 200){
            dispatch(updateOrderCart(response.data));
        }
        return response;
    } catch (error) {
        return {
            message: "Có lỗi sảy ra, vui lòng thử lại sau",
            signal: 0
        };
    }
};
export const getLast = async (payload, dispatch) => {
    try {
        let response = await fetchApi(`/app/order/getLast`, "get", payload);
        if (response.code === 200){
            dispatch(updateOrderCart(response.data));
        }
        return response;
    } catch (error) {
        return {
            message: "Có lỗi sảy ra, vui lòng thử lại sau",
            signal: 0
        };
    }
};
export const checkout = async (payload, dispatch) => {
    try {
        let response = await fetchApi(`/app/order/checkout`, "post", payload);
        if (response.code === 200){
            dispatch(updateOrderCart({}));
        }
        return response;
    } catch (error) {
        return {
            message: "Có lỗi sảy ra, vui lòng thử lại sau",
            signal: 0
        };
    }
};

