import { HYDRATE } from 'next-redux-wrapper';
import { combineReducers } from 'redux';
import product from './product';
import auth from './auth';
import layout from './layout';
import cart from './cart';
import payment from './payment';
import store from './store';
import user from './user';
import chart from './chart';
import news from './news';
import refferal from './refferal';
import history from './history';
import agency from './agency';
import price_chart from './gold_price_chart';
const _reducers = combineReducers({
  product,
  news,
  chart,
  user,
  auth,
  layout,
  payment,
  store,
  cart,
  agency,
  refferal,
  history,
  price_chart
});

function reducers(state, action) {
  if (action.type === HYDRATE) {
    return { ...state, ...action.payload };
  }
  return _reducers(state, action);
}

export default reducers;