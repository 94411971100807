const initialState = {
    listRefferal: [],
    overview : {}
};
import {
    LIST_REFFERAL, OVERVIEW_REFERRAL
} from '@/constants/ActionTypes';


const payment = (state = initialState, { type, payload }) => {
    let newState = Object.assign({}, state);
    switch (type) {
        case LIST_REFFERAL:
            return {
                ...newState,
                listRefferal: payload
            };
        case OVERVIEW_REFERRAL:
            return {
                ...newState,
                overview: payload
            };
        default:
            return { ...newState };
    }
};



export default payment;