const initialState = {
    listProduct: [],
    listCategory: [],
};
import {
    LOAD_CATEGORY_PRODUCT, LOAD_LIST_PRODUCT
  } from '@/constants/ActionTypes';
 
  
  const product = (state = initialState, { type, payload }) => {
    let newState = Object.assign({}, state);
    switch (type) {
    case LOAD_LIST_PRODUCT:
        return {
          ...newState,
          listProduct: payload
        };
    case LOAD_CATEGORY_PRODUCT:
        return {
          ...newState,
          listCategory: payload
        };
      default:
        return { ...newState };
    }
  };

  

export default product;