// const API url
const API_SERVER_URL = process.env.NEXT_PUBLIC_API_SERVER_URL || 'https://api.hanagold.vn';
export default {
  /**
   * Get user list
   * @method GET
   */
  getUserList: `${API_SERVER_URL}/api/v1/users`,
  getListProduct: `${API_SERVER_URL}/app/product/list`,
  getUserInfo: `${API_SERVER_URL}/app/auth/user-info`,
  getListCategory: `${API_SERVER_URL}/app/product/list-category`,
};