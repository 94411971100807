
import axios from 'axios';
const API_URL = process.env.NEXT_PUBLIC_API_SERVER_URL || 'https://api.hanagold.vn';
const axiosInstance = axios.create({
    baseURL: API_URL,
    timeout: 5000,
    headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        token: ''
    },
});

axiosInstance.interceptors.request.use(function (config) {
    config.headers.token = localStorage.getItem("token");
    return config;
}, function (error) {
    console.log(error);
    return Promise.reject(error);
});


export default axiosInstance;